.App {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
 }

@media only screen and (max-width: 900px ) {
    .App {
        display: grid;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width:100%;
        min-height: 90vh;
    }
}
@font-face {
    font-family: 'Marianne-ExtraBold';
    src: url(../src/assets/fonts/Marianne-ExtraBold.otf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne-Bold';
    src: url(../src/assets/fonts/Marianne-Bold.otf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne-Regular';
    src: url(../src/assets/fonts/Marianne-Regular.otf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Marianne-Light';
    src: url(../src/assets/fonts/Marianne-Light.otf) format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'metropolis-Medium';
    src: url(../src/assets/fonts/metropolis.medium.otf) format('truetype');
    font-style: normal;
    font-display: swap;
}
